import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import LayoutGalerieImageInteractive from "../../../composants/layout/LayoutGalerieImageInteractive"
import Pannellum from "../../../composants/image/Panellum"

import { obtenirPage } from "../../../js/client-es/utils"
import fragmentsPages from "../../../js/client-es/fragments/fragments-pages"

export const requete = graphql`
  {
    page: allPagesCompilationYaml(filter: { code: { eq: "teide360" } }) {
      ...FragmentPageYaml
    }
  }
`

export default function Teide360(props) {
  const resultatsRequete = useStaticQuery(requete)
  const page = obtenirPage(resultatsRequete, "page")

  const composantImageInteractive = (
    <Pannellum urlImageSource="/ressources/360/uneNuitAuPiedDuTeidesd.jpg" />
  )

  return (
    <LayoutGalerieImageInteractive
      page={page}
      propsPage={props}
      composantImageInteractive={composantImageInteractive}
      codePageRetour="galerieZoomsEt360Degres"
    >
      <section className="section-conteneur">
        <p>
          Les conditions météo hivernales peuvent être rudes dans les îles
          Canaries. La seule nuit claire de notre séjour de décembre 2017 s’est
          déroulée alors que nous faisions étape dans l’hôtel Parador qui se
          trouve en plein du cœur du parc national du Teide sur l’île de
          Tenerife.
        </p>
        <div id="galerie-image-interactive-texte-exifs">
          Panoramique de 44 images, rotule panoramique Bushman Gobi, Canon EOS
          6D refiltré Astrodon, Samyang 24 mm F1.4 @ F2.2, 3200 ISO. Poses
          unitaires de 20 secondes.
        </div>
      </section>
    </LayoutGalerieImageInteractive>
  )
}
